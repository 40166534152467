<template>
  <div>
    <div class="container">
      <div id="map" style="width:100%;height:100%"></div>
      <div class="btnmode" @click="showLayer">
        <span v-if="satelliteMode">标准</span>
        <span v-else>卫星</span>
      </div>
    </div>

    <el-drawer
      :visible.sync="drawer"
      :direction="'btt'"
      :title="title"
      :modal="false"
      destroy-on-close
      size="50%"
    >
      <div class="infobox">
        <div class="imgthumbbox">
          <img
            v-for="(item,i) in tapPosition.imgs"
            :key="i"
            :src="item"
            @click="openPic()"
            alt
            class="imgwrap"
          />
        </div>
        <div class="infoset" v-if="tapPosition.type==0">景区入口</div>
        <div class="infoset" v-else>
          <div>类别： {{tapPosition.typeName}}</div>
          <div>距入口： {{tapPosition.distance}}</div>
        </div>
        <div class="introbox" v-if="tapPosition.intro">{{tapPosition.intro}}</div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible" width="100%" top="2vh" destroy-on-close>
      <el-carousel height="100vh">
        <el-carousel-item v-for="item in imgs" :key="item">
          <div class="picbox">
            <img :src="item" alt class="picstyle" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import { getSightforWeb } from "../api/apiv4";
let map, satellite;
var AMap = window.AMap;
var labelsLayer = new AMap.LabelsLayer({
  zooms: [3, 20],
  zIndex: 1000,
  // 该层内标注是否避让
  collision: false,
  // 设置 allowCollision：true，可以让标注避让用户的标注
  allowCollision: true
});

export default {
  data() {
    return {
      idSight: "",
      positions: [],
      satelliteMode: false,
      latlng: {
        lat1: 0,
        lat2: 0,
        lng1: 0,
        lng2: 0
      },
      title: "",
      drawer: false,
      sight: {},
      dialogVisible: false,
      dataObj: "",
      tapPosition: {}
    };
  },
  mounted() {
    this.idSight = this.getQueryVariable("id");
    this.getSight();
    this.initMap();
  },
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    initMap() {
      // const that = this;
      map = new AMap.Map("map", {
        zoom: 11, //设置地图显示的缩放级别
        // mapStyle: "amap://styles/whitesmoke", //设置地图的显示样式
        animateEnable: true
      });
      satellite = new window.AMap.TileLayer.Satellite({ zoom: 14 });
    },

    getSight() {
      const that = this;
      let latlng = that.latlng;
      latlng.type = -1;
      getSightforWeb({
        id: this.idSight
      }).then(res => {
        that.sight = res.data.sight;
        that.positions = res.data.positions;
        that.setMarkerSight();
      });
    },
    setMarkerSight() {
      const that = this;
      let lbMarkers = [];
      that.positions.forEach(element => {
        // let img = require("@/assets/sighticon0.png");
        var text = {
          // 要展示的文字内容
          content: element.name,
          // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
          direction: "bottom",
          // 在 direction 基础上的偏移量
          offset: [0, -5],
          // 文字样式
          style: {
            // 字体大小
            fontSize: 11,
            // 字体颜色
            fillColor: "#22886f",
            // 描边颜色
            strokeColor: "#fff",
            // 描边宽度
            strokeWidth: 2
          }
        };
        let iconPath = element.iconPath.replace("/ast", "");
        let image = require("@/assets" + iconPath);

        var icon = {
          // 图标类型，现阶段只支持 image 类型
          type: "image",
          // 图片 url
          image: image,
          // 图片尺寸
          size: [20, 20]
          // 图片相对 position 的锚点，默认为 bottom-center
        };
        var labelMarker = new AMap.LabelMarker({
          name: element.id, // 此属性非绘制文字内容，仅最为标识使用
          position: [element.longitude, element.latitude],
          zIndex: 16,
          // 将第一步创建的 icon 对象传给 icon 属性
          icon: icon,
          // 将第二步创建的 text 对象传给 text 属性
          text: text
        });
        labelMarker.on("click", function(e) {
          that.onMarkerClick(e.target._opts.name);
        });
        lbMarkers.push(labelMarker);
      });
      this.lbMarkers = lbMarkers;
      labelsLayer.add(lbMarkers);
      map.add(labelsLayer);
      map.setFitView();
    },

    setMarkers() {
      const that = this;
      this.positions.forEach(item => {
        let iconPath = item.iconPath.replace("/ast", "");
        let image = require("@/assets" + iconPath);
        var labelContent = "<div>anchor:" + item.name + "</div>";
        let icon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(20, 20),
          // 图标的取图地址
          image: image
        });
        // 将 Icon 传入 marker
        let marker = new AMap.Marker({
          position: [item.longitude, item.latitude],
          icon: icon,
          anchor: "bottom", //设置锚点
          offset: new AMap.Pixel(0, 0), //设置偏移量
          label: {
            direction: "bottom",
            content: labelContent
          }
        });
        marker.on("click", function(e) {
          that.onMarkerClick(e.target._opts.name);
        });
        map.add(marker);
      });
      map.setFitView();
    },

    onMarkerClick(id) {
      let index = this.positions.findIndex(item => {
        return item.id == id;
      });
      this.tapPosition = this.positions[index];
      this.title = this.sight.name + " ~ " + this.positions[index].name;
      this.drawer = true;
    },

    openDrawer(title) {
      this.title = title;
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    showLayer() {
      if (this.satelliteMode) {
        map.removeLayer(satellite);
        this.satelliteMode = false;
      } else {
        this.satelliteMode = true;
        map.addLayer(satellite);
      }
    },

    openPic() {
      let imgs = [];
      this.tapPosition.imgs.forEach(item => {
        let rsp = item.replace(".thumb", "");
        imgs.push(rsp);
      });
      this.imgs = imgs;
      this.dialogVisible = true;
    },

    handlePicClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>

.container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
}
.btnmode {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0 0 5px #999;
}


.el-drawer__body {
  overflow: auto;
}

.imgthumbbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.imgwrap {
  width: 31%;
  margin-right: 2%;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #eee;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.picbox {
  display: flex;
  justify-content: center;
  height: 400px;
}
.picstyle {
  height: 500px;
  border-radius: 10px;
  max-width: 80%;
}

.infobox {
  padding: 0 20px;
}

.infoset {
  margin: 10px 0;
  line-height: 18px;
}

.introbox {
  margin-top: 5px;
  border-top: 1px dashed #aaa;
  padding: 10px 0;
}
</style>